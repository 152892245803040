import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./App.css";
import FlippingCard from "./FlippingCard";

export default function Program() {
  const volunteers = [
    {
      alt: "pic1",
      pic: require("./public/Volunteers/pic1.png"),
      name: "Ely Bor",
      school: "University of Florida",
      Description:
        "My favorite part of the program is that all of my ‘little one’ are very eager to learn. I enjoy exchanging knowledge about the culture and an open space to share our ideas. They bring so much joy, love, and happiness to my life.",
    },
    {
      alt: "pic2",
      pic: require("./public/Volunteers/pic2.png"),
      name: "Lucy Par",
      school: "University of Indiana, Bloomington",
      Description:
        "One thing I enjoy most about the program is being able to teach the kids. I love how they’re always so eager to learn, so respectful, and always asking questions. Seeing them so happy when they’re learning makes me want to do the best I can for them. More than just teaching, we are able to build a connection between each other, and it’s very heart warming to me",
    },
    {
      alt: "pic3",
      pic: require("./public/Volunteers/pic3.png"),
      name: "Elena Par",
      school: "Wheaton College, Illinois",
      Description:
        "My favorite part about this program is witnessing our students grow. They are so attentive and engaged during class. It feels so special to be apart of it all. I love seeing their smiley faces! I look forward to Fridays and Sundays because of them.",
    },
    {
      alt: "pic4",
      pic: require("./public/Volunteers/pic4.png"),
      name: "Linda Tial",
      school: "University of Indiana, Bloomington",
      Description:
        "My favorite  part about the program is spending time with my students; not only teaching them but also learning from them and with them. They always come to class so eager and passionate to learn, which inspires me to work harder in my own academic life! I really appreciate their desire to learn!",
    },
    {
      alt: "pic5",
      pic: require("./public/Volunteers/pic5.png"),
      name: "Paris",
      school: "University of Indiana, Bloomington",
      Description:
        "As someone who places a great emphasis on education, I enjoy seeing the students taking education serious and getting very enthusiastic about learning. When the students were asked about their future goals, many of them have goals to lift up our country through their knowledge and education despite the current situation in Burma. If this is the future of Burma, I have pretty good feeling about our country!",
    },
    {
      alt: "pic6",
      pic: require("./public/Volunteers/pic6.png"),
      name: "Geneve Par",
      school: "Lakeview High School",
      Description: "",
    },
    {
      alt: "pic7",
      pic: require("./public/Volunteers/pic7.png"),
      name: "Rosey Sung",
      school: "University of Michigan, Ann Arbor",
      Description:
        "My favorite part of this program is that I have the opportunity to reconnect with my language and culture. I am learning a lot from our students as I get to know them better. Being a part of this program has been a very enjoyable, fulfilling, and eye-opening experience.",
    },
    {
      alt: "pic8",
      pic: require("./public/Volunteers/pic8.png"),
      name: "Besheba Sung",
      school: "Kellog Community College",
      Description: "",
    },
    {
      alt: "pic9",
      pic: require("./public/Volunteers/pic9.png"),
      name: "Ami Hnem",
      school: "University of Indiana, Bloomington",
      Description: "",
    },
    {
      alt: "pic10",
      pic: require("./public/Volunteers/pic10.png"),
      name: "Mah Sui",
      school: "University of Michigan, Ann Arbor",

      Description: "",
    },
    {
      alt: "pic11",
      pic: require("./public/Volunteers/pic11.png"),
      name: "Iris Par",
      school: "Lake Orion High School",
      Description: "",
    },
    {
      alt: "pic12",
      pic: require("./public/Volunteers/pic12.png"),
      name: "Dawt Ku",
      school: "Lakeview High School",
      Description: "",
    },
    {
      alt: "pic13",
      pic: require("./public/Volunteers/pic13.png"),
      name: "Biak Par",
      school: "Western Michigan University",
      Description: "",
    },
    {
      alt: "pic14",
      pic: require("./public/Volunteers/pic15.png"),
      name: "Teresa Lian",
      school: "Cornell University",
      Description: "",
    },
  ];

  return (
    <>
      <Header />
      <div className="bg-[#F6FAFF] h-full w-screen flex flex-col items-center">
        <p className="text-black text-6xl pt-12 pb-2 font-isto text-center">
          LEARN ENGLISH!
        </p>
        <p className="text-black font-isto text-center">
          Launched On January 2022 to provide English lessons to children in
          Myanmar, ages 10-16, through zoom.
        </p>
        <div className="bg-white sm:w-8/12 w-11/12 rounded-lg mt-6 mb-12 flex justify-center">
          <div className="w-4/5 mt-16">
            <p className="font-bold text-lg">Program Overview</p>
            <div className="flex sm:flex-row gay-y-4 flex-col sm:justify-evenly mt-6 w-full lg:h-[400px] md:h-[450px] sm:h-[500px]">
              <div className="bg-[#EEFFE940] rounded-3xl flex flex-col items-center sm:w-[30%] h-full">
                <div className="w-36 h-36 mt-8 border-2 rounded-full flex justify-center items-center">
                  <p className="text-center text-[#878686] font-bold font-isto text-xl">
                    Duration
                    <br />
                    of Program
                  </p>
                </div>
                <p className="text-center mt-4 w-10/12">
                  Classes began on Febuary 2022 and ended May 2022.
                </p>
              </div>
              <div className="bg-[#FFE9FF40] rounded-3xl flex flex-col items-center sm:w-[30%] h-full">
                <div className="w-36 h-36 mt-8 border-2 rounded-full flex justify-center items-center">
                  <p className="text-center text-[#878686] font-bold font-isto text-xl">
                    Program Structure
                  </p>
                </div>
                <p className="text-center mt-4 w-10/12">
                  Zoom classes were held for 2 hours a day for twice a week
                  every week until may 2022.
                </p>
              </div>
              <div className="bg-[#FFFDE940] rounded-3xl flex flex-col items-center sm:w-[30%] h-full">
                <div className="w-36 h-36 mt-8 border-2 rounded-full flex justify-center items-center">
                  <p className="text-center text-[#878686] font-bold font-isto text-xl">
                    Assistance Funds
                  </p>
                </div>
                <p className="text-center mt-4 w-10/12">
                  Olive Chin Baptist Church and Mr. Lian Hei Cung helped cover
                  the cost of zoom services and teaching materials.
                </p>
              </div>
            </div>
            <p className="font-bold mt-6">Student</p>
            <p className="mt-2">
              To protect the privacy of our students, we are unable to reveal
              their names. However, around 100 students registered to our
              program, and we were able to successfully connect with around 50
              students.
            </p>
            <p className="font-bold mt-6">Volunteers</p>
            <p className="mt-2">
              The program thrives on high achieving high school and college
              student volunteers that compassionately dedicate their time to
              help children in Myanmar improve their English speaking, reading,
              and writing skills.
            </p>

            <div className="grid sm:grid-cols-5 grid-cols-2 gap-x-5 gap-y-8 mt-6">
              {volunteers.map((image) => (
                <FlippingCard src={image.pic} alt={image.alt} name={image.name} school={image.school} description={image.Description}/>
              ))}
            </div>
            <p className="font-bold mt-6">Reason For Program Termination</p>
            <p className="mt-2">
              The program ended May 2022 due to lack of sufficient funds to
              provide financial assistant to children in Myanmar for their
              internet fees. Additionally, students in the Chin state of Myanmar
              experiences power outage frequently, which prevented them from
              being able to attend their classes.
            </p>
            <p className="font-bold mt-6">Upcoming Plans</p>
            <p className="mt-2 mb-10">
              ITWE began hosting fundraising events to be able to financially
              support children in Myanmar who are eager to learn and hopes to be
              able to continue the program soon.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
