import React from 'react';
import Header from './Header';
import "./styles.css";
import Carousel from "react-spring-3d-carousel";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import Card from "./Card";
import CardNews from "./CardNews";
import { config } from "react-spring";
import Carroussel from "./Carroussel";

export default function Team() {

    let cards = [
        {
            key: uuidv4(),
            content: <CardNews src={require('./public/teresa.jpg')} name="TERESA LIAN" job="CO-PRESIDENT" />
        },
        {
            key: uuidv4(),
            content: <CardNews src={require('./public/rosey.jpg')} name="ROSEY SUNG" job="CO-PRESIDENT" />
        },
        {
            key: uuidv4(),
            content: <CardNews src={require('./public/geneve.jpg')} name="GENEVE PAR" job="CO-VICE PRESIDENT" />
        },
        {
            key: uuidv4(),
            content: <CardNews src={require('./public/mang.jpg')} name="MANG THAWNG" job="CO-SOCIAL MEDIA MANAGER" />
        },
        {
            key: uuidv4(),
            content: <CardNews src={require('./public/ami.jpg')} name="AMI ZATHANG" job="CO-SOCIAL MEDIA MANAGER" />
        },
        {
            key: uuidv4(),
            content: <CardNews src={require('./public/teresa2.jpg')} name="TERESA DAWT" job="CO-VICE PRESIDENT" />
        },
        {
            key: uuidv4(),
            content: <CardNews src={require('./public/mah.jpg')} name="MAH SUI" job="CO-VICE PRESIDENT" />
        },
        {
            key: uuidv4(),
            content: <CardNews src={require('./public/thawng.JPG')} name="THAWNG HMUNG" job="WEBSITE ADMINISTRATOR" />
        },
    ];

    return (
        <>
            <Header />
            <div className="flex flex-col justify-center items-center sm:h-[80vh] h-[60vh]">
                <p className="font-julius sm:text-4xl text-xl">
                    BOARD OF DIRECTORS
                </p>
                <Carroussel
                    cards={cards}
                    height="500px"
                    width="80%"
                    margin="0 auto"
                    offset={2}
                    showArrows={false}
                />
            </div>

        </>

    );
}
