import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import emailjs from '@emailjs/browser';

import './App.css'

export default function Contacts() {

    const [submitted, setSubmitted] = useState(false);

    const [values, setValues] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleSubmit = (e) => {

        e.preventDefault(); // Prevents default refresh by the browser
        emailjs.send('service_14q879r', 'template_v4wzpeo', values, 'll74-oG-ry7GSMx1T')
            .then(response => {
                console.log('SUCCESS!', response);
                setSubmitted(true);
            }, error => {
                console.log('ERROR:', error);
            })
    };

    const handleChange = (e) => {
        setValues(values => ({
            ...values, [e.target.name]: e.target.value
        }));
    }

    const renderForm = (
        <form onSubmit={handleSubmit} className="mx-6 sm:mx-36 w-full">
            <p className="sm:text-3xl font-isto">
                We would love to hear from you.
            </p>
            <p className="text-bold sm:text-5xl text-xl font-isto">
                Get in touch!
            </p>
            <div className="py-6 md:mt-0">
                <div className="md:flex md:gap-x-4 w-full">
                    <div className="w-full">
                        <label htmlFor="first-name" className="text-sm font-medium text-gray-700">
                            Name<span className='text-red-600'>*</span>
                        </label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            onChange={handleChange}
                            autoComplete="given-name"
                            className="mt-1 bg-[#F3F3F3] rounded-lg focus:ring-[#0066FF] focus:border-[#0066FF] w-full block shadow-sm sm:text-sm border-gray-300"
                        />
                    </div>
                    <div className="w-full">
                        <label htmlFor="email-address" className="text-sm font-medium text-gray-700">
                            Email<span className="text-red-600">*</span>
                        </label>
                        <input
                            type="text"
                            name="email"
                            id="email"
                            onChange={handleChange}
                            placeholder='you@example.com'
                            autoComplete="email"
                            className="mt-1 bg-[#F3F3F3] rounded-lg focus:ring-[#0066FF] focus:border-[#0066FF] block shadow-sm sm:text-sm border-gray-300 w-full"
                        />
                    </div>
                </div>
                <div className="w-full mt-2">
                    <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                        Message<span className="text-red-600">*</span>
                    </label>
                    <div className="mt-1">
                        <textarea
                            id="message"
                            name="message"
                            onChange={handleChange}
                            className="shadow-sm bg-[#F3F3F3] focus:ring-[#0066FF] focus:border-[#0066FF] mt-1 w-full h-64 sm:text-sm border border-gray-300 resize-none"
                            defaultValue={''}
                        />
                    </div>
                </div>
            </div>
            <button
                type="submit"
                className="mt-4 inline-flex justify-center py-2 px-16 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-lgblue hover:bg-[#FFD2B1] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                SUBMIT <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                    <path fillRule="evenodd" d="M8.25 3.75H19.5a.75.75 0 01.75.75v11.25a.75.75 0 01-1.5 0V6.31L5.03 20.03a.75.75 0 01-1.06-1.06L17.69 5.25H8.25a.75.75 0 010-1.5z" clipRule="evenodd" />
                </svg>
            </button>
        </form >
    );

    const renderSubmit = (
        <>
            <div className="mt-64">
                <p className="font-isto sm:text-3xl text-lg text-black break-words text-center">
                    THANK YOU FOR CONTACTING ITWE, WE WILL GET BACK TO YOU SOON!
                </p>
            </div>
        </>
    );

    return (
        <>
            <Header />
            <div className="flex items-start sm:pt-5 w-screen h-screen">
                {submitted ? renderSubmit : renderForm}
            </div>
            <Footer bg="#F6FAFF"/>
        </>
    );
}
