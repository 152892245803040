import React from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Homepage from './Home';
import Program from './Programs';
import Contact from './contact';
import Network from './network';
import Team from './team';

function App() {
  return (
  <Router>
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/program" element={<Program />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/network" element={<Network />} />
      <Route path="/team" element={<Team />} />
    </Routes>
  </Router>
  );
}

export default App;
