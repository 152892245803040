import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import ChevronRightRounded from "@material-ui/icons/ChevronRightRounded";
import TextInfoContent from "@mui-treasury/components/content/textInfo";
import { useWideCardMediaStyles } from "@mui-treasury/styles/cardMedia/wide";
import { useN01TextInfoContentStyles } from "@mui-treasury/styles/textInfoContent/n01";
import { useFourThreeCardMediaStyles } from '@mui-treasury/styles/cardMedia/fourThree';
import { useBouncyShadowStyles } from "@mui-treasury/styles/shadow/bouncy";

import picture from './public/contact.jpg';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: 304,
        width: 400,
        margin: "auto",
        boxShadow: "none",
        borderRadius: 30
    },
    content: {
        padding: 24.,
    },
    cta: {
        marginTop: 24,
        textTransform: "initial"
    }
}));

/*interface CardNews {
  hasImg?:boolean;
  srcImg: string;
  width?: number;
  hasTitle?: boolean;
  title: string;
  hasDescription?: boolean;
  description: string;
  hasStars?: boolean;
  hasLink?: boolean;
  borderRadius?: number;
}*/

/*defaultProps {
  width: 400,
  borderRadius: 30,
  hasTitle: false,
  hasStars: false,
  hasImg: false,
  hasLink: false,
}*/
export const NewsCardDemo = React.memo(function NewsCard(props) {
    const styles = useStyles();
    const mediaStyles = useFourThreeCardMediaStyles();
    // const mediaStyles = useWideCardMediaStyles();
    const textCardContentStyles = useN01TextInfoContentStyles();
    const shadowStyles = useBouncyShadowStyles();

    console.log(props);
    return (
        <Card className={cx(styles.root, shadowStyles.root)}>
            <CardMedia
                style={{ height: "250px" }}
                classes={mediaStyles}
                image={props.src}
            />
            <CardContent className={styles.content}>

                <TextInfoContent
                    style={{ height: "300px", paddingTop: "2%", backgroundColor: "red" }}
                    classes={textCardContentStyles}
                    heading={props.name}
                    body={props.job}
                />
            </CardContent>
        </Card>
    );
});

export default NewsCardDemo;
