import React, { useState } from 'react';

const FlippingCard = (props) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleCardFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className={`card ${isFlipped ? 'flipped' : ''}`}       onMouseEnter={handleCardFlip}
    onMouseLeave={handleCardFlip}>
      <div className="front">
        <img src={props.src} alt={props.alt}/>
      </div>
      <div className="back p-1 rounded-lg">
        <h2 className="text-black font-isto">{props.name}</h2>
        <p className="text-[#C0C0C0] font-isto text-xs">{props.school}</p>
        <p className="text-black font-isto text-xs w-full h-3/4 overflow-hidden">"{props.description}"</p>
      </div>
    </div>
  );
};

export default FlippingCard;
