/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";

import { scroller } from "react-scroll";
import { useNavigate, useLocation } from "react-router-dom";

import instagramb from "./public/instagram-black.png";
import facebookb from "./public/facebook-black.png";

import "./App.css";

import { MenuIcon, XIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const history = useNavigate();
  const scrollTarget = (target) =>
    scroller.scrollTo(target, { smooth: true, duration: 700 });

  const scrollToPage = async (target) => {
    await history("/");
    scrollTarget(target);
  };

  const options = [
    { title: "About us", href: "/#aboutus", name: "aboutus", key: "1" },
    {
      title: "Mission",
      href: "/#scrollMission",
      name: "scrollMission",
      key: "2",
    },
    { title: "Vision", href: "/#vision", name: "vision", key: "3" },
    { title: "Values", href: "/#value", name: "value", key: "4" },
  ];

  return (
    <Popover className="w-screen bg-white z-50">
      <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 z-50">
        <div className="flex justify-between items-center py-2 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="/">
              <img src={require("./public/logo.png")} alt="Logo" />
            </a>
          </div>
          <div className="-mr-2 -my-2 md:hidden flex">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0 gap-4">
            <a
              href="/program"
              className="text-lg font-inter font-medium text-black hover:underline hover:decoration-dotted"
            >
              Programs
            </a>
            <a
              href="/team"
              className="text-lg font-inter font-medium text-black hover:underline hover:decoration-dotted"
            >
              Team
            </a>
            <a
              href="/network"
              className="text-lg font-inter font-medium text-black hover:underline hover:decoration-dotted"
            >
              Network
            </a>
            <a
              href="/contact"
              className="text-lg font-inter font-medium text-black hover:underline hover:decoration-dotted"
            >
              Contact
            </a>
            <a
              href="https://www.paypal.com/donate/?business=ZE45VAXY7F5L6&no_recurring=0&item_name=Help+expand+access+to+education+in+the+Chin+State+of+Myanmar&currency_code=USD"
              target="_blank"
              rel="noreferrer"
            >
              <div className="rounded-md border-2 ml-2 border-black hover:bg-black">
                <p className="text-lg font-isto text-black py-2 px-6 hover:text-white">
                  Donate
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>

      <Popover.Panel
        focus
        className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50"
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white">
          <div className="pt-5 pb-6 px-5">
            <div className="flex items-center justify-between">
              <div>
                <a href="/" className="font-isto text-black text-xl">
                  In Tandem With Education
                </a>
              </div>
              <div className="-mr-2">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="mt-2">
              <nav className="grid gap-y-10">
                <Menu as="div">
                  <div className="translate-y-3">
                    <Menu.Button className="text-black hover:bg-gray-700 hover:text-white font-federo rounded-lg text-md font-medium">
                      About
                    </Menu.Button>
                  </div>

                  <Menu.Items className="translate-y-4">
                    <div className="py-1">
                      {options.map((option) => {
                        return (
                          <Menu.Item>
                            <div
                              onClick={() => scrollToPage(option.name)}
                              className="text-base text-black block px-4 py-3 -m-3 pl-3 items-center rounded-md bg-slate-100"
                            >
                              {option.title}
                            </div>
                          </Menu.Item>
                        );
                      })}
                    </div>
                  </Menu.Items>
                </Menu>
                <a
                  key="program"
                  href="/program"
                  className="-m-3 pl-3 flex items-center rounded-md hover:bg-gray-50"
                >
                  <p className="text-base font-medium text-gray-900">Program</p>
                </a>
                <a
                  key="program"
                  href="/team"
                  className="-m-3 pl-3 flex items-center rounded-md hover:bg-gray-50"
                >
                  <p className="text-base font-medium text-gray-900">Team</p>
                </a>
                <a
                  key="network"
                  href="/network"
                  className="-m-3 pl-3 flex items-center rounded-md hover:bg-gray-50"
                >
                  <p className="text-base font-medium text-gray-900">Network</p>
                </a>
                <a
                  key="contact"
                  href="/contact"
                  className="-m-3 pl-3 flex items-center rounded-md hover:bg-gray-50"
                >
                  <p className="text-base font-medium text-gray-900">Contact</p>
                </a>
              </nav>
            </div>
          </div>
          <div className="py-6 px-5 space-y-6">
            <div>
              <a
                href="https://www.paypal.com/donate/?business=ZE45VAXY7F5L6&no_recurring=0&item_name=Help+expand+access+to+education+in+the+Chin+State+of+Myanmar&currency_code=USD"
                target="_blank"
                rel="noreferrer"
                className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-black bg-lgblue"
              >
                Support
              </a>
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  );
}
