import React from 'react';
import Header from './Header';

export default function Network() {

    const inst = [
        {
            dis: 'Hannah Oo is a student at Standford University who scored a perfect 1600 on the SAT and 5’s on multiple AP exams. She is a very community oriented person and would be happy to answer your questions regarding the SAT and AP exams. Additionally, she also tutors some student with the SAT.',
            src: require('./public/network/Hannah.png')
        },
    ];

    const renderppl = (
        <>
            <p className="text-center sm:text-3xl text-2xl">CONNECT WITH YOUR FELLOW<br />BURMESE PEOPLE AROUND THE GLOBE</p>

            <div className='mt-10 sm:px-44 grid grid-cols-1 justify-items-between items-center gap-y-8'>
                {inst.map((item) =>
                    <div className='bg-lgblue flex sm:flex-row flex-col justify-between items-center rounded-[21px] w-full'>
                        <div className='flex justify-center'>
                            <p className="text-lg sm:text-center font-jeju w-10/12">{item.dis}</p>
                        </div>
                        <img className="sm:w-80 w-full" src={item.src} alt="" />
                    </div>
                )}
            </div>
        </>
    );

    return (
        <>
            <Header />
            {renderppl}
        </>
    );
}
