import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

import "./App.css";
import glasses from "./public/Glasses.png";
import Hand from "./public/Hand.png";
import Mision from "./public/Mission.png";

import Aos from "aos";
import "aos/dist/aos.css";

import { scroller } from "react-scroll";
import { useNavigate } from "react-router-dom";

export default function Homepage() {
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  const handleClick = () => {
    window.location =
      "https://www.paypal.com/donate/?business=ZE45VAXY7F5L6&no_recurring=0&item_name=Help+expand+access+to+education+in+the+Chin+State+of+Myanmar&currency_code=USD"; // Update the page_name.php with your desired page URL
  };

  const history = useNavigate();
  const scrollTarget = (target) =>
    scroller.scrollTo(target, { smooth: true, duration: 700 });

  const scrollToSection = async (target) => {
    await history(target);
  };

  const renderPage = (
    <>
      <div id="scrollMission" name="scrollMission" className="top-0">
        <div
          data-aos="zoom-in"
          data-aos-duration="2000"
          className="bg-white sm:h-screen h-96 flex sm:flex-row-reverse flex-col items-center justify-around grow mt-2 sm:mt-0"
        >
          <div data-aos="fade-up" data-aos-duration="3000">
            <div className="bg-lgblue rounded-2xl w-80 sm:h-96 sm:flex items-center justify-center slider hidden">
              <img
                src={Mision}
                className="h-[250px] w-[250px] sm:h-auto"
                alt="Mission"
              />
            </div>
          </div>

          <div className="sm:w-2/5">
            <p className="font-baloo sm:text-9xl text-4xl text-black pb-10">
              MISSION
            </p>
            <p className="font-francois sm:text-3xl text-lg text-black break-words">
              Our mission is to inspire social justice, growth, and enrichment
              of the Burmese community through education.
            </p>
          </div>
        </div>
      </div>
      <div
        data-aos="zoom-in"
        data-aos-duration="2000"
        id="vision"
        name="vision"
        className="sm:h-screen h-96 flex sm:flex-row-reverse flex-col items-center justify-around grow bg-lgblue"
      >
        <div className="w-80 h-96 sm:block hidden">
          <img
            src={glasses}
            className="h-[250px] w-[250px] sm:h-auto glasses"
            alt="Glasses"
          />
        </div>

        <div className="sm:w-2/5">
          <p className="font-baloo sm:text-9xl text-4xl text-black pb-10">
            VISION
          </p>
          <p className="font-francois sm:text-3xl text-lg text-black break-words">
            We envision a vibrant community where individuals from Myanmar can
            forge meaningful connections and thrive academically and
            professionally.
          </p>
        </div>
      </div>

      <div
        data-aos="zoom-in"
        data-aos-duration="2000"
        id="value"
        name="value"
        className="bg-white flex sm:flex-row-reverse flex-col items-center justify-around grow"
      >
        <div className="sm:bg-lgblue rounded-2xl sm:w-80 h-96 sm:items-center sm:justify-center sm:flex hidden">
          <div
            data-aos="zoom-out"
            data-aos-duration="3000"
            data-aos-delay="1000"
          >
            <img
              src={Hand}
              className="h-[250px] w-[250px] sm:h-auto"
              alt="Glasses"
            />
          </div>
        </div>

        <div className="sm:w-2/5 py-5">
          <p className="font-baloo sm:text-9xl text-4xl text-black pb-10">
            VALUES
          </p>
          <p className="font-francois sm:text-2xl text-lg text-[#878686] break-words">
            <span className="text-black">Learning:</span> We foster a culture of
            continuous growth, inspiring each other to embrace challenges,
            strive for improvement, and embrace lifelong learning.
            <br /> <br />
            <span className="text-black">Humanity:</span> ITWE was established
            to provide assistance to all people from Myanmar without financial
            discrimination, promoting equity and fostering success within our
            community.
            <br /> <br />
            <span className="text-black">Service:</span> ITWE is a non-profit
            organization driven by dedicated youths from Myanmar, who volunteer
            their knowledge, time, and commitment to uplift our community,
            driven by a genuine desire to make a positive impact.
            <br /> <br />
            <span className="text-black">Youth Empowerment:</span> We believe in
            the boundless potential of our young generation, recognizing them as
            the vanguards of a brighter future the people of Myanmar. We invest
            in their growth, development, and empowerment, empowering them to
            shape their own destiny and contribute meaningfully to our
            collective progress.
          </p>
        </div>
      </div>
    </>
  );

  return (
    <>
      <Header />
      <div className="main h-screen flex justify-center flex-col items-center grow">
        <div className="text-center md:flex md:flex-col md:justify-center md:items-center w-screen">
          <p className="text-white md:text-6xl text-2xl font-bold font-isto">
            In Tandem With Education
          </p>
          <p className="text-center text-bold text-white sm:text-3xl text-xl break-normal md:w-3/6">
            To Inspire Social Justice, Growth, and Enrichment of the Burmese
            Community Through Education
          </p>
        </div>
        <button
          onClick={handleClick}
          className="rounded-lg bg-[#ffffffb3] hover:bg-white mt-5 drop-shadow-lg"
        >
          <p className="text-black text-lg py-3 px-7">DONATE NOW</p>
        </button>
        <button
          onClick={() => scrollToSection("/program")}
          className="rounded-lg bg-[#ffffffb3] hover:bg-white mt-6 drop-shadow-lg"
        >
          <p className="text-black text-lg py-3 px-8">LEARN MORE</p>
        </button>
      </div>
      <div className="py-16" id="aboutus" name="aboutus">
        <div data-aos="zoom-in" data-aos-duration="1500">
          <p className="text-black sm:text-6xl text-4xl text-center">
            ABOUT US
          </p>
          <div className="flex justify-center">
            <p className="text-[#878686] sm:text-4xl text-2xl text-center w-9/12 sm:mt-20 mt-16">
              We are a group of youths who strive to provide free educational
              services and resources for all children and youths of Myanmar.
            </p>
          </div>
          <div className="flex justify-evenly mt-16 sm:mx-12">
            <div>
              <p className="sm:text-3xl text-xl">officers</p>
              <p className="sm:text-3xl text-xl text-center">8</p>
            </div>
            <div>
              <p className="sm:text-3xl text-xl">Volunteers</p>
              <p className="sm:text-3xl text-xl text-center">10+</p>
            </div>
            <div>
              <p className="sm:text-3xl text-xl">Programs</p>
              <p className="sm:text-3xl text-xl text-center">2</p>
            </div>
            <div>
              <p className="sm:text-3xl text-xl">Events</p>
              <p className="sm:text-3xl text-xl text-center">3</p>
            </div>
          </div>
          <div className="bg-[#F4F9FF] mt-20">
            <p className="text-center text-4xl pt-12 font-isto">
              UPCOMING EVENTS
            </p>
            <p className="text-[#878686] text-center text-xl mt-10 font-isto">
              There are no upcoming event!
            </p>
            <a
              href="https://www.instagram.com/in.tandem.with.education/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
            >
              <p className="text-[#0066FF] text-center sm:text-xl text-lg font-isto mt-8 pb-8">
                FOLLOW OUR INSTAGRAM TO KEEP UPDATED
              </p>
            </a>
          </div>
          <div className="accomplish flex flex-col justify-center items-center">
            <p className="text-center text-4xl text-black font-isto font-[400] pt-24">
              TOGETHER, WE HAVE ACCOMPLISHED...
            </p>
            <p className="text-center text-black font-isto text-xl mt-12 sm:w-5/12">
              We have provided English lessons to over 50 students in Myanmar
              through our Learn English Program, awarded 3 students in Chin
              State of Myanmar with scholarships through Cornell Contribution
              Project, and awarded scholarship awards to 4 students at Chin
              Christian University through ITWE fundraising efforts.
            </p>
            <img
              src={require("./public/team2.png")}
              alt="Team"
              className="w-full mt-8 pb-16"
            />
          </div>
          <div>
            <p className="text-center text-4xl font-isto pt-20">
              WHAT’S NEXT ON OUR AGENDA?
            </p>
            <div className="flex justify-center mt-16">
              <div className="w-96 rounded-lg border sm:mr-4 mr-2 div-shadow">
                <p className="sm:m-4 m-2 sm:text-xl text-lg">
                  In Tandem With Education is committed to connecting Burmese
                  youths who shares the same passion with one another and plans
                  to provide the Burmese youths with opportunities to network!
                </p>
              </div>
              <div className="w-96 rounded-lg border sm:ml-4 ml-2 div-shadow">
                <p className="sm:m-4 m-2 sm:text-xl text-lg">
                  By the end of 2024, In Tandem With Education sets a goal to
                  provide 100 students with personal Chromebook to join their
                  zooms and have access to abundance of educational resources
                  online.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {renderPage}
      <Footer bg="#F6FAFF" />
    </>
  );
}
