import React from "react";

import privacy from "./public/ITWE-PRIVACY-POLICY.pdf";
import term from "./public/ITWE-Terms-of-Service.pdf";

export default function Footer(props) {
  return (
    <div className={`bg-[${props.bg}]`}>
      <div className="m-auto md:w-5/6 sm:w-11/12">
        <p className="py-12 font-isto text-2xl">
          Get in touch. Email{" "}
          <span className="font-black">intandemwitheducation@gmail.com</span>{" "}
          anytime
        </p>
        <div className="w-full border-[1px] border-solid border-black"></div>
        <div className="py-12 md:flex w-full md:justify-between text-center">
          <div className="md:w-1/3">
            <div className="md:flex justify-between font-isto">
              <div className="flex flex-col">
                <a href={term} target="_blank" rel="noreferrer">
                  Terms of Use
                </a>
                <a href={privacy} target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
              </div>
              <div className="flex flex-col font-bold">
                <a href="/">About Us</a>
                <a href="/program">Programs</a>
              </div>
              <div className="flex flex-col font-bold">
                <a href="/contact">Contact</a>
                <a href="https://www.paypal.com/donate/?business=ZE45VAXY7F5L6&no_recurring=0&item_name=Help+expand+access+to+education+in+the+Chin+State+of+Myanmar&currency_code=USD" target="_blank" rel="noreferrer">Donate</a>
              </div>
            </div>
          </div>

          <p className="font-isto text-lg text-[#BFBFBF]">
            IN TANDEM WITH EDUCATION
          </p>
        </div>
      </div>
    </div>
  );
}
